<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <div>
    <el-container>
      <el-header>
        <AppLogo to="/admin" />
        <el-button link @click="signOut">
          Sign out
          <el-icon class="el-icon--right"><IconLogout /></el-icon>
        </el-button>
      </el-header>
      <el-container>
        <slot />
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ElButton, ElContainer, ElHeader, ElIcon } from 'element-plus'
import { Auth } from '@aws-amplify/auth'
import { navigateTo } from '#app'
import AppLogo from '~/components/AppLogo.vue'
import IconLogout from '~/components/icons/IconLogout.vue'

export default defineComponent({
  name: 'Admin',
  components: {
    AppLogo,
    ElButton,
    ElContainer,
    ElHeader,
    ElIcon,
    IconLogout,
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut()
        navigateTo('/', { external: true })
      } catch (err) {
        console.log(err)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.el-header {
  --el-color-info: var(--el-color-white);
  --el-text-color-regular: var(--el-color-white);
  --el-text-color-info: var(--el-color-white);
  --el-text-color-primary: var(--el-color-white);

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--el-color-primary);
  color: var(--el-color-white);
}
</style>
