<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.959" height="20.5" viewBox="0 0 20.959 20.5">
    <g id="Group_27566" data-name="Group 27566" transform="translate(804.25 5987.26)">
      <path
        id="Vector"
        d="M12.574,5.059C12.264,1.467,10.418,0,6.377,0h-.13C1.786,0,0,1.786,0,6.247v6.506C0,17.214,1.786,19,6.247,19h.13c4.012,0,5.858-1.447,6.187-4.98"
        transform="translate(-803.5 -5986.51)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <g id="Group_27564" data-name="Group 27564" transform="translate(-798.011 -5980.363)">
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H12.853"
          transform="translate(0 3.343)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0,3.343,3.343,0,6.686"
          transform="translate(10.628)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>
